import React, { useContext, useState } from 'react'
import { Formik, Form } from 'formik';
import useFetch from 'use-http'
import * as yup from 'yup'
import { navigate } from 'gatsby';
import isArray from 'lodash/isArray'

import Box from 'components/Box'
import Flex from 'components/Flex'
import Board from 'components/Board'
import Text from 'components/Text'
import Image from 'components/Image'
import Input from 'components/Input'
import Button from 'components/Button'
import AreaDropdown from 'components/AreaDropdown'
import { responsive } from 'components/ThemeProvider/theme';

import progressContext from 'contexts/progress/context'

import icon from './searchicon.png'
import Captcha from '../Captcha'
import captchaShape from '../Captcha/shape'

const inputTitle = [
  // {
  //   options: [
  //     { title: '聲請人', id: 1 }, { title: '對造人', id: 2 }
  //   ],
  //   type: 'radio',
  //   name: 'mantype',
  // },
  {
    label: '調解委員會',
    name: 'acceptorgid',
    Comp: AreaDropdown.Field,
  },
  {
    label: '聲請人或對造人姓名',
    name: 'name',
  },
  {
    label: '聯絡電話或手機',
    name: 'phone',
  },
]

const validationSchema = yup.object().shape(inputTitle.reduce((all, field) => {
  all[field.name] = yup.string().required()
  return all
}, { captcha: captchaShape }))

const PersonalInfo = (props) => (
  <Box {...props}>
    {inputTitle.map(({ options, Comp = Input.Field, label, type, name }, index) => (
      <Comp py="0.75em" name={name} options={options} label={label} type={type} key={index} />
    ))}
  </Box>
)

const Progress = () => {
  const { post } = useFetch('/progress')
  const { handleRes } = useContext(progressContext)
  const [formErr, setFormError] = useState()
  return (
    <Board pt={responsive('2.625em', '3.125em')}>
      <Formik
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          const res = await post(values)
          if (res['captcha.input']) {
            setFieldError('captcha.uuid', 'Invalid')
          }
          if (res.error) {
            setFormError(res.error)
            setFieldError('captcha.uuid', 'Refresh')
          } else if (isArray(res)) {
            handleRes(res)
            navigate('/progress/result')
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Flex flexDirection={responsive('column', 'row')}>
              <Flex
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                borderRight={responsive('none', '1px solid')}
                borderColor={responsive('none', 'purple')}
                width={responsive(1, 1 / 3)}
              >
                <Box width="9em" mx="auto">
                  <Image src={icon} alt="放大鏡Icon" />
                </Box>
                <Text mt="1em">查詢前請先輸入資料</Text>
              </Flex>
              <PersonalInfo mt={responsive('1em', 0)} flex={1} px="1.75em" borderLeft={responsive('none', '1px solid')} borderColor={responsive('none', 'purple')} />
            </Flex>
            <Box textAlign="center" mt="2em">
              <Captcha />
              <Button disabled={isSubmitting} mt="1.5em" type="submit" fontSize="1.25rem" px="2em">查詢</Button>
              {formErr && <Text mt="0.5em" color="danger">{formErr}</Text>}
            </Box>
          </Form>
        )}
      </Formik>
    </Board>
  )
}

export default Progress
